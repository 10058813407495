<template>
  <div class="flex items-center">
    <navigation-button
      v-for="(navItem, navIndex) in brandAssuranceNavigationList"
      :key="'brandAssuranceNavItem' + navIndex"
      :nav-text="navItem.navText"
      :route-name="navItem.routeName"
    />
  </div>
</template>

<script>
import { defineAsyncComponent, ref } from 'vue';
import useUserAuth from '@/hooks/userAuth.js';

import {
    BA_ADVANCE_SEARCH_NAME, BA_COLLABORATION_NAME, BA_COMPLETED_SUBMISSIONS_NAME,
    BA_DRAFTS_NAME, BA_INBOX_NAME, BA_INFLIGHT_SUBMISSIONS_NAME, BA_NEW_SUBMISSION_REQUEST_NAME,
    BA_PRODUCT_LINE_REPORT_NAME, BA_SYSTEM_ADMIN_NAME
} from '@/constants/routes';

import { BAC_ADMIN_ROLE, BA_ADMIN_ROLE, BAC_REVIEWER_ROLE, BAC_SUPERVISOR_ROLE, COLLABORATOR_ROLE, CREATIVE_ROLE, LEGAL_ROLE, LICENSEE_ROLE, SAFETY_ROLE, SOCIAL_REVIEWER_ROLE, REPORTING_ACCESS_ROLE, BA_RO_ROLE } from '@/constants/ba-roles.js';

export default {
    name: 'BrandAssuranceNavigationTabs',

    components: {
        NavigationButton: defineAsyncComponent(() => import('./NavigationButton.vue'))
    },

    setup () {
        const { userRoles } = useUserAuth();
        const brandAssuranceNavigationList = ref([]);

        if ([LICENSEE_ROLE, BAC_REVIEWER_ROLE, SOCIAL_REVIEWER_ROLE, LEGAL_ROLE, CREATIVE_ROLE, SAFETY_ROLE, BAC_SUPERVISOR_ROLE, BAC_ADMIN_ROLE, BA_ADMIN_ROLE, BA_RO_ROLE].some(role => userRoles.value.includes(role))) {
            brandAssuranceNavigationList.value.push({
                navText: 'Inbox',
                routeName: BA_INBOX_NAME
            });
        }
        if ([LICENSEE_ROLE, BAC_REVIEWER_ROLE, BAC_SUPERVISOR_ROLE, BA_ADMIN_ROLE].some(role => userRoles.value.includes(role))) {
            brandAssuranceNavigationList.value.push({
                navText: 'New Submission Request',
                routeName: BA_NEW_SUBMISSION_REQUEST_NAME
            });
        }
        // showing advance search for userRoles.
        brandAssuranceNavigationList.value.push({
            navText: 'Advanced Search',
            routeName: BA_ADVANCE_SEARCH_NAME
        });
        if ([LICENSEE_ROLE, BAC_REVIEWER_ROLE, BAC_SUPERVISOR_ROLE, BA_ADMIN_ROLE].some(role => userRoles.value.includes(role))) {
            brandAssuranceNavigationList.value.push({
                navText: 'Drafts',
                routeName: BA_DRAFTS_NAME
            });
            brandAssuranceNavigationList.value.push({
                navText: 'In-Flight Submissions',
                routeName: BA_INFLIGHT_SUBMISSIONS_NAME
            });
        }
        if ([LICENSEE_ROLE, BAC_REVIEWER_ROLE, SOCIAL_REVIEWER_ROLE, LEGAL_ROLE, CREATIVE_ROLE, BAC_SUPERVISOR_ROLE, BA_ADMIN_ROLE, BA_RO_ROLE].some(role => userRoles.value.includes(role))) {
            brandAssuranceNavigationList.value.push({
                navText: 'Completed Submissions',
                routeName: BA_COMPLETED_SUBMISSIONS_NAME
            });
        }
        if ([BAC_REVIEWER_ROLE, SOCIAL_REVIEWER_ROLE, LEGAL_ROLE, COLLABORATOR_ROLE, CREATIVE_ROLE, BAC_ADMIN_ROLE, SAFETY_ROLE, BAC_SUPERVISOR_ROLE, BA_ADMIN_ROLE].some(role => userRoles.value.includes(role))) {
            brandAssuranceNavigationList.value.push({
                navText: 'Collaboration',
                routeName: BA_COLLABORATION_NAME
            });
        }
        if ([REPORTING_ACCESS_ROLE, BAC_SUPERVISOR_ROLE, BA_ADMIN_ROLE, BAC_REVIEWER_ROLE].some(role => userRoles.value.includes(role))) {
            brandAssuranceNavigationList.value.push({
                navText: 'Product Line Report',
                routeName: BA_PRODUCT_LINE_REPORT_NAME
            });
        }
        if ([BAC_SUPERVISOR_ROLE, BA_ADMIN_ROLE].some(role => userRoles.value.includes(role))) {
            brandAssuranceNavigationList.value.push({
                navText: 'System Administration',
                routeName: BA_SYSTEM_ADMIN_NAME
            });
        }

        return {
            brandAssuranceNavigationList
        };
    }
};
</script>
